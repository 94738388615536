<template xmlns="http://www.w3.org/1999/html">
    <div class="flex ">
        <div class="flex flex-nowrap overflow-x-auto pb-2 mr-5">
            <!-- SELECTED FILTERS -->
            <div class="flex-none " v-for="(filter_selected,group) in website.search.selected_filters_categories">
                <div @click.passive="mobileFiltersOpen(true,group)"
                     class="btn-default-gray !ml-0 mr-1 block lg:hidden" v-if="group!=='categories'">
                   <span class="whitespace-nowrap text-xs mr-1" >{{ website.filter_names[group] }}</span>
                    <span class="text-xs sm:text-sm bg-green-700 rounded p-0.5 pl-2 pr-2 mr-0 text-white  cursor-pointer whitespace-nowrap
                dark:bg-gray-300 dark:text-black dark:border-gray-100 dark:border" v-if="Object.keys(filter_selected).length >0">{{ Object.keys(filter_selected).length }}</span>
                </div>
                <div v-for="filter in filter_selected" class="hidden lg:block" v-if="group!=='categories'">
                    <div class="float-left" @click.passive="deleteThisFilter(group,filter.url)">
                <span class=" rounded border-1 text-white bg-green-700 border border-green-700 cursor-pointer whitespace-nowrap !no-underline float-left border rounded p-1 pr-1 mr-1 text-sm font-medium text-gray-700 hover:text-gray-200 border-gray-300 border rounded p-1.5 text-s ">{{ filter.name }} x</span>
                    </div>
                </div>

            </div>


            <div class="float-left text-sm hidden lg:block">

                <DeleteAllFilters  :website="website" :min="min" :max="max"
                                   @website_filter_reset="onRemoveFilters"></DeleteAllFilters>

                <div class="float-left pt-1 text-sm">
                    <div @click.passive="deleteSearch" v-if="website.search.q!==undefined && website.search.q!=='' && website.search.q!==null"
                         class="text-xs sm:text-sm bg-white rounded p-0.5 mt-0.5 pl-2 pr-2 mr-2 text-green-700 border border-green-700 cursor-pointer whitespace-nowrap dark:bg-gray-300 dark:text-black dark:border-gray-100">
                        Zoekopdracht: <b>{{ website.search.q }}</b> x
                    </div>
                </div>
            </div>
        </div>
            <!-- VOORDE -->
            <Menu as="div" class="relative inline-block text-left float-right z-index-10 hidden lg:block">

                <div>
                    <MenuButton
                        class="btn-default-gray ">
                        <span class="whitespace-nowrap text-xs  mx-1">{{ filterName() }}</span>
                        <CustomSvg :name="'chevron-down'" color="red" class="text-gray-600 mr-1 dark:text-white "
                                   :customClass="'fill-gray-500 w-3 mt-0.5 !float-right ml-2'"></CustomSvg>
                    </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                    <MenuItems
                        class="absolute right-0 z-11 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none text-xs
        dark:bg-gray-800
">
                        <div class="py-1">

                            <MenuItem v-for="option in sortOptions"
                                      :key="option.name" v-slot="{ active }">
                                <div @click.passive="setSorting(option.type)"
                                     :class="[ 'cursor-pointer block px-4 py-2 text-sm']">
                                    {{ option.name }}
                                </div>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>

</template>

<script>

import JetNavLink from '@/Jetstream/NavLink.vue'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
import DeleteAllFilters from "@/Pages/Admin/Page/Templates/Partials/DeleteAllFilters.vue";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'


export default {

    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        JetNavLink,
        CustomSvg,
        DeleteAllFilters
    },
    props: {
        website: Object,
        sorting: String,
        max: Number,
        min: Number,
    },

    data() {
        return {
            sortOptions: [
                {name: 'Populariteit', type: 'popularity'},
                {name: 'Nieuwste', type: 'newest'},
                {name: 'Prijs laag-hoog', type: 'price_low_high'},
                {name: 'Prijs hoog-laag', type: 'price_high_low'},
            ],
        }
    },
    methods: {
        deleteThisFilter(category, filter) {
            let index = this.website.search.selected_filters[category].indexOf(filter);
            if (index > -1) {
                delete this.website.search.selected_filters[category][index];
            }
            this.onRemoveFilters();
        },
        setSorting(sort) {
            this.website.search.page = 0;
            this.website.search.sorting = sort;
            this.onChange();
        },
        deleteSearch() {
            this.website.search.q = '';
            this.onChange();
        },
        deletePriceFilter() {
            this.$emit('website_filter_reset_price');
        },
        filterName() {
            for (var option in this.sortOptions) {
                if (this.sortOptions[option].type === this.website.search.sorting) {
                    return this.sortOptions[option].name;
                }
            }
        },
        onChange(event) {
            this.$emit('website_search', this.website.search);
        },
        onRemoveFilters(event) {
            this.$emit('website_filter_reset', this.website.search);
        },
        mobileFiltersOpen(temp, group) {
            this.$emit('mobileFiltersOpen', temp, group);
        }

    },
    computed: {}
}
</script>
